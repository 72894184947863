import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorReview } from "../models/DoctorReview";

export default {
  insert(review: IDoctorReview) {
    return AuthAxios.post(ApiURIs.DrReviewInsert, JSON.stringify(review));
  },

  update(review: IDoctorReview) {
    return AuthAxios.put(ApiURIs.DrReviewUpdate, JSON.stringify(review));
  },

  GetReviewByCurrentUser(doctorId: number) {
    return AuthAxios.get(
      `${ApiURIs.DrReviewGetListByUser}?doctorId=${doctorId}`
    );
  },

  getDoctorReview(doctorId: number) {
    return AuthAxios.get(`${ApiURIs.DrGetDoctorReview}?doctorId=${doctorId}`);
  },

  getDoctorReviewUserInfo(doctorId: number) {
    return AuthAxios.get(
      `${ApiURIs.DrGetDoctorReviewUserInfo}?doctorId=${doctorId}`
    );
  },

  GetReviewByCurrentDoctor() {
    return AuthAxios.get(ApiURIs.DrReviewGetListByDoctor);
  },

  delete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrReviewDelete}/${id}`);
  }
};
