






















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import DoctorChamberService from "../service/DoctorChamberService";
import { IDoctorChamber } from "../models/DoctorChamber";

@Component
export default class DoctorSideNav extends Vue {
  //public chambers: IDoctorChamber[] = this.storeChambers;
  created() {
    DoctorChamberService.GetListByUser().then((res) => {
      if (res.data.result) {
        //this.chambers = res.data.result;
        this.$store.dispatch("addChamberList", res.data.result);
      }
    });
  }

  public get storeChambers(): IDoctorChamber[] {
    this.$store.watch(
      (state) => {
        return this.$store.getters.chamberList;
      },
      (val) => {
        //something changed do something
        //console.log(val);
        return val || [];
      },
      {
        deep: true,
      }
    );
    return this.$store.getters.chamberList || [];
  }
}
