import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorChamber } from "../models/DoctorChamber";

export default { 
  GetListByUser() {
    return AuthAxios.get(ApiURIs.DrChamberListByUser);
  },

  GetAllChamberByUser() {
    return AuthAxios.get(ApiURIs.DrAllChamberListByUser);
  },

  insert(chamber: IDoctorChamber) {
    return AuthAxios.post(ApiURIs.DrChamberInsert, JSON.stringify(chamber));
  },
  update(chamber: IDoctorChamber) {
    return AuthAxios.put(ApiURIs.DrChamberUpdate, JSON.stringify(chamber));
  },
  delete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrChamberDelete}/${id}`);
  }
};
