






































































































import { Vue, Component, Prop } from "vue-property-decorator";
import ProfileWidget from "../../common-app/components/ProfileWidget.vue";
import DoctorSideNav from "../components/DoctorSideNav.vue";
import DoctorReviewService from "../service/DoctorReviewService";
import AuthUser from "../../global-config/AuthUser";
import { IDoctorDetail } from "../models/DoctorDetail";
import DoctorService from "../service/DoctorService";
import DoctorDetailService from "../service/DoctorDetailService";
import { ICountry } from "../../common-app/models/Country";
import { ISpecialization } from "../../common-app/models/Specialization";
import { IDoctorSpecialization } from "../models/DoctorSpecialization";
import { IDoctorExprience } from "../models/DoctorExprience";
import { IDoctorEducation } from "../models/DoctorEducation";
import { IDoctorAward } from "../models/DoctorAward";
import DoctorChamberService from "../service/DoctorChamberService";
import { IDoctorChamber } from "../models/DoctorChamber";

@Component({
  components: {
    ProfileWidget,
    DoctorSideNav,
  },
})
export default class Dashboard extends Vue {
  public detail: IDoctorDetail = {} as IDoctorDetail;
  public countryList: ICountry[] = [];
  public specializationList: ISpecialization[] = [];
  public doctorSpecializationList: IDoctorSpecialization[] = [];
  public doctorExperienceList: IDoctorExprience[] = [];
  public doctorEducationList: IDoctorEducation[] = [];
  public doctorAwardList: IDoctorAward[] = [];
  public totalComplete: number = 0;
  public isShow: boolean = false;
  public user = AuthUser.getAuthUser();
  public chambers: IDoctorChamber[] = [];
  public isVirtualChamber:boolean=false;

  created() {


    DoctorService.refreshProfileComplete().subscribe((res) => {
      if (res) {
        this.getProfileStatus();
        //alert("refres");
      }
    });
    
    this.getProfileStatus();

    if(AuthUser.getId()==undefined||AuthUser.getId()==0){
      return;
    }
    // DoctorReviewService.getDoctorReview(AuthUser.getId()).then((res) => {
    //   this.$store.dispatch("AddRating", res.data.result.rating || 0);
    //   this.$store.dispatch("AddFavourite", res.data.result.favourite || 0);
    // });

    // DoctorService.GetDetails(Number(AuthUser.getId())).then((s) => {
    //   this.detail = s.data.doctorDetails || ({} as IDoctorDetail);
    // });

    // DoctorDetailService.getProfileList().then((s) => {
    //   this.countryList = s.data.countries || [];
    //   this.specializationList = s.data.specializations || [];
    //   this.doctorSpecializationList = s.data.doctorSpecializations || [];
    //   this.doctorExperienceList = s.data.doctorExperiences || [];
    //   this.doctorEducationList = s.data.doctorEducations || [];
    //   this.doctorAwardList = s.data.doctorAwards || [];
    //   this.detail = s.data.doctorDetails || ({} as IDoctorDetail);

    //   this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 20;
    //   this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 20;
    //   this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 20;
    //   this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 20;
    //   this.totalComplete += this.doctorAwardList.length === 0 ? 20 : 20;
    //  this.detail.isProfileCompleted==1? this.isShow=false:this.isShow=true;
    // });
    // if(this.user.email=='' || this.user.email==null && this.totalComplete>0){
    //   this.totalComplete+=-10;
    // }
    // // if(this.totalComplete!=100 && this.detail.isProfileCompleted==0){
    // //   this.isShow=true;
    // // }
    // // if(this.isProfileCompleted!=1){
    // //   console.log(this.isProfileCompleted);
    // //   this.isShow=true;
    // // }

    //     DoctorChamberService.GetAllChamberByUser().then((res) => {
    //   if (res.data.result) {
    //     this.chambers = res.data.result || [];
    //     this.chambers = this.chambers.filter((s) => s.isVirtual ==true ) || [];
    //     //console.log(this.chambers.length);
    //     if(this.chambers.length>0){
    //       this.isVirtualChamber=true;
    //     }
    //   }
    // });
  }

  getProfileStatus(){
    this.totalComplete=0;
    if(AuthUser.getId()==undefined||AuthUser.getId()==0){
      return;
    }
    DoctorReviewService.getDoctorReview(AuthUser.getId()).then((res) => {
      this.$store.dispatch("AddRating", res.data.result.rating || 0);
      this.$store.dispatch("AddFavourite", res.data.result.favourite || 0);
    });

    DoctorService.GetDetails(Number(AuthUser.getId())).then((s) => {
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);
    });

    DoctorDetailService.getProfileList().then((s) => {
      this.countryList = s.data.countries || [];
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];
      this.doctorAwardList = s.data.doctorAwards || [];
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);

      this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 20;
      this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorAwardList.length === 0 ? 20 : 20;
     this.detail.isProfileCompleted==1? this.isShow=false:this.isShow=true;
    });
    if(this.user.email=='' || this.user.email==null && this.totalComplete>0){
      this.totalComplete+=-10;
    }
    // if(this.totalComplete!=100 && this.detail.isProfileCompleted==0){
    //   this.isShow=true;
    // }
    // if(this.isProfileCompleted!=1){
    //   console.log(this.isProfileCompleted);
    //   this.isShow=true;
    // }

        DoctorChamberService.GetAllChamberByUser().then((res) => {
      if (res.data.result) {
        this.chambers = res.data.result || [];
        this.chambers = this.chambers.filter((s) => s.isVirtual ==true ) || [];
        //console.log(this.chambers.length);
        if(this.chambers.length>0){
          this.isVirtualChamber=true;
        }
      }
    });
  }
}
